import React, { memo } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Seo from '../components/common/Seo';
import { checkExpriedPromotion } from '../utils/helpers';
import { useStore } from '../hooks';

const GraphQLError = loadable(() => import('../components/error'));
const Layout = loadable(() => import('../layout'));
const PromotionsContaier = loadable(() => import('../components/promotions_page'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicPromotionsPage: {
      data: { meta_title, meta_description },
    },
  } = data;
  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: [],
    location,
  };

  return <Seo {...seo} />;
};

const PromotionsPage = (props) => {
  const { data, errors, uri, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }

  const {
    prismicPromotionsPage: {
      data: { title_page, h1_header, body },
    },
    allPrismicRule,
  } = data;

  const { rules } = useStore();

  let promotions = {};
  allPrismicRule?.group?.forEach(({ nodes }) => {
    promotions = {
      ...promotions,
      [nodes[0]?.data?.promotion_block[0]?.category?.uid]: nodes
        ?.map(({ prismicId, data: { product_page_text, brand, promotion_block } }) =>
          !checkExpriedPromotion(rules, prismicId)
            ? {
                prismicId,
                product_page_text,
                ...promotion_block[0],
                brand: promotion_block[0]?.brand?.document?.data
                  ? promotion_block[0]?.brand
                  : brand,
              }
            : null
        )
        .filter((v) => v),
    };
  });

  return (
    <Layout location={location}>
      <h1 hidden>{h1_header}</h1>

      <PromotionsContaier title_page={title_page} body={body} promotions={promotions} />
    </Layout>
  );
};

export default memo(PromotionsPage);

export const query = graphql`
  {
    prismicPromotionsPage {
      data {
        h1_header
        meta_description
        meta_title
        title_page
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...PromotionsPageDataBodyImageBlock
          ...PromotionsPageDataBodyPromotionsBlock
          ...PromotionsPageDataBodyTCBlock
        }
      }
    }

    allPrismicRule {
      group(field: data___promotion_block___category___uid) {
        nodes {
          prismicId
          data {
            product_page_text {
              richText
              text
            }
            brand {
              uid
              type
              link_type
              document {
                ... on PrismicBrand {
                  data {
                    name
                    url
                  }
                }
              }
            }
            promotion_block {
              title
              feature
              category {
                uid
                type
                link_type
                document {
                  ... on PrismicCategory {
                    data {
                      name
                      url
                    }
                  }
                }
              }
              brand {
                uid
                type
                link_type
                document {
                  ... on PrismicBrand {
                    data {
                      name
                      url
                    }
                  }
                }
              }
              image {
                gatsbyImageData(breakpoints: 600)
                alt
                thumbnails {
                  lifestyle {
                    gatsbyImageData(breakpoints: 600)
                    alt
                  }
                }
              }
              product {
                title
                handle
              }
            }
          }
        }
      }
    }
  }
`;
